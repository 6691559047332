<template>
  <div class="Userhl_info">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="个人资料" name="first">
        <Userhlinfoziliao />
      </el-tab-pane>
      <el-tab-pane label="信息认证" name="two">
        <Userhlinforz />
      </el-tab-pane>
      <el-tab-pane label="密码设置" name="three">
        <Userhlinfomm @phonjezero="phonjezerotext" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Userhlinfoziliao from "../Userhomeleft/Userhl_info/Userhl_info_ziliao.vue";
import Userhlinforz from "../Userhomeleft/Userhl_info/Userhl_info_rz.vue";
import Userhlinfomm from "../Userhomeleft/Userhl_info/Userhl_info_mm.vue";
export default {
  components: { Userhlinfoziliao, Userhlinforz, Userhlinfomm },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    phonjezerotext(name){
      this.activeName="two";
    },
  },
  created() {
    var id = this.$route.query.data;
    if(id==2){
      this.activeName="two";
    }
  },
};
</script>
