<template>
  <div class="Userhl_info_mm">
    <ul class="Userhl_info_mmul" v-if="mmtype">
      <li class="Userhl_info_mmli">
        <div class="Userhl_info_mmlil">
          <p>登陆密码</p>
          <p>
            密码要求至少包含字母或数字的6-16位，建议您经常修改密码以保证账号更加安全
          </p>
        </div>
        <div class="Userhl_info_mmlir">
          <div class="Userhl_info_mmlirbtn" @click="changemm(0)">修改</div>
        </div>
      </li>
      <li class="Userhl_info_mmli">
        <div class="Userhl_info_mmlil">
          <p>支付密码</p>
          <p>
            密码要求至少包含字母或数字的6-16位，建议您经常修改密码以保证账号更加安全
          </p>
        </div>
        <div class="Userhl_info_mmlir">
          <div class="Userhl_info_mmlirbtn" @click="changemm(1)">修改</div>
        </div>
      </li>
    </ul>
    <div class="Userhl_info_mmdl" v-if="dltype">
      <p>修改登录密码</p>
      <p>{{ ponint }}</p>
      <div class="Userhl_info_mmdl_yzmbox">
        <el-input v-model="dldx" placeholder="请输入短信验证码"></el-input>
        <input
          type="button"
          class="Userhl_info_mm_yzmbtmn"
          @click="dlyzm(0)"
          :value="shejishim"
          readonly="readonly"
          :class="yazmfalse"
          :style="yazmcolor"
        />
      </div>
      <div class="Userhl_info_mmdl_nmm">
        <el-input v-model="dlmm" placeholder="请输入新的登录密码"></el-input>
      </div>
      <div class="Userhl_info_mmdl_nmm">
        <el-input
          v-model="dlmm2"
          placeholder="请再次输入新的登录密码"
        ></el-input>
      </div>
      <div class="Userhl_info_mmdl_subbtn">
        <div @click="submm(0)">返回</div>
        <div @click="submm(1)">确定</div>
      </div>
    </div>
    <div class="Userhl_info_mmdl" v-if="zftype">
      <p>修改支付密码</p>
      <div class="Userhl_info_mmdl_yzmbox">
        <el-input v-model="dldx" placeholder="请输入短信验证码"></el-input>
        <input
          type="button"
          class="Userhl_info_mm_yzmbtmn"
          @click="dlyzm(0)"
          :value="shejishim"
          :class="yazmfalse"
          :style="yazmcolor"
        />
      </div>
      <div class="Userhl_info_mmdl_nmm">
        <el-input v-model="dlmm" placeholder="请输入新的支付密码"></el-input>
      </div>
      <div class="Userhl_info_mmdl_nmm">
        <el-input
          v-model="dlmm2"
          placeholder="请再次输入新的支付密码"
        ></el-input>
      </div>
      <div class="Userhl_info_mmdl_subbtn">
        <div @click="submm(2)">返回</div>
        <div @click="submm(3)">确定</div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="phonezero" width="30%">
      <div>
        <p>{{ phonezerotext }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="phonezerobtn()">立即前往</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mmtype: true,
      dltype: false,
      zftype: false,
      input: "",
      dldx: "",
      dlmm: "",
      dlmm2: "",
      shejishim: "获取验证码",
      ponint: "0",
      timer: null,
      count: 0,
      yazmfalse: "",
      yazmcolor: "color:#666;",
      userinfo: {},
      phonezero: false,
      r_zfbnum: "",
      phonezerotext: "请绑定您的手机号码，再进行密码修改。",
    };
  },
  components: {},
  methods: {
    changemm(id) {
      var that = this;
      if (that.ponint == null) {
        // 没有手机号的处理
        that.phonezero = true;
      } else {
        if (id == 0) {
          that.mmtype = false;
          that.dltype = true;
        } else {
          if (that.r_zfbnum != "") {
            that.mmtype = false;
            that.zftype = true;
          } else {
            that.phonezero = true;
            that.phonezerotext =
              "请绑定实名认证中您的支付宝信息，再进行支付密码修改。";
          }
        }
      }
    },
    submm(id) {
      var that = this;
      if (id == 0 || id == 2) {
        that.dlmm = "";
        that.dlmm2 = "";
        that.dldx = "";
        that.zftype = false;
        that.dltype = false;
        that.mmtype = true;
      } else if (id == 1 || id == 3) {
        // 提交 登录密码/支付密码 修改
        var apitype = "";
        if (id == 1) {
          apitype = "forgot";
        } else {
          apitype = "payPass";
        }
        if (that.dlmm == that.dlmm2) {
          this.$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/" + apitype + "",
            method: "post",
            data: {
              mobile: that.ponint,
              password: that.dlmm,
              code: that.dldx,
            },
          }).then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });
              that.dlmm = "";
              that.dlmm2 = "";
              that.dldx = "";
              that.zftype = false;
              that.dltype = false;
              that.mmtype = true;
            } else {
              this.$message({
                message: res.data.messages,
                duration: 1000,
              });
            }
          });
        } else {
          this.$message({
            message: "两次密码输入不一致，请核对后重新输入",
            type:'error',
            duration: 3000,
          });
          that.dlmm = "";
          that.dlmm2 = "";
          that.dldx = "";
        }
      }
    },
    dlyzm(id) {
      var that = this;
      that.yazmfalse = "yazmfalse";
      var get_type = "";
      if (id == 0) {
        get_type = "ResetPass";
      } else {
        get_type = "PayPass";
      }
      // 登陆密码验证码 / 支付密码验证码
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/package/getMobileCode",
        method: "post",
        data: {
          mobile: that.ponint,
          get_type,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: "验证码发送成功，请注意查收",
            duration: 1000,
          });
          const TIME_COUNT = 60;
          if (that.timer == null) {
            that.count = TIME_COUNT;
            that.yazmcolor = "background:#eeee";
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= TIME_COUNT) {
                that.count--;
                that.shejishim = "" + that.count + "秒后重新获取";
                that.yazmfalse = "yazmfalse";
              } else {
                that.login_pccenterboxmmprohqbk = "background:#000";
                clearInterval(that.timer);
                that.timer = null;
                that.shejishim = "获取验证码";
                that.yazmfalse = "";
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: "验证码发送失败，请刷新页面重新发送！",
            duration: 1000,
          });
        }
      });
    },
    phoneaxios() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getUserInfoData",
        method: "post",
        data: {},
      }).then((res) => {
        that.ponint = res.data.result.mobile;
      });
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getIdentity",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.r_zfbnum = res.data.result.alipay;
          } else {
          }
        });
    },
    phonezerobtn() {
      this.phonezero = false;
      this.$emit("phonjezero", "two");
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.phoneaxios();
  },
};
</script>
