<template>
  <div class="Userhl_info_rz">
    <ul class="Userhl_info_rzbox" v-if="allbox">
      <li class="Userhl_info_rzboxli">
        <div class="Userhl_info_rzboxli_l">
          <icon-svg icon-class="iconshouji54" />
          <span>手机认证</span>
        </div>
        <div class="Userhl_info_rzboxli_r">
          <el-button size="small" @click="rz(1)" :disabled="disabledphone">{{
            phonetext
          }}</el-button>
        </div>
      </li>
      <li class="Userhl_info_rzboxli">
        <div class="Userhl_info_rzboxli_l">
          <icon-svg icon-class="iconshenfenzheng" />
          <span>实名认证</span>
        </div>
        <div class="Userhl_info_rzboxli_r">
          <el-button size="small" @click="rz(2)">{{ shimingtext }}</el-button>
        </div>
      </li>
      <li class="Userhl_info_rzboxli">
        <div class="Userhl_info_rzboxli_l">
          <icon-svg icon-class="iconyouxiang" />
          <span>邮箱认证</span>
        </div>
        <div class="Userhl_info_rzboxli_r">
          <el-button size="small" @click="rz(3)">{{
            xinxiall.email != "" ? "修改认证" : "去认证"
          }}</el-button>
        </div>
      </li>
      <li class="Userhl_info_rzboxli">
        <div class="Userhl_info_rzboxli_l">
          <icon-svg icon-class="iconshangjiabaozhengjinshezhiliebiao" />
          <span>保证金</span>
        </div>
        <div class="Userhl_info_rzboxli_r">
          <el-button
            :disabled="xinxiall.isguara != '0' ? true : false"
            size="small"
            @click="rz(4)"
            >{{ xinxiall.isguara != "0" ? "已认证" : "去认证" }}</el-button
          >
        </div>
      </li>
      <li class="Userhl_info_rzboxli">
        <div class="Userhl_info_rzboxli_l">
          <icon-svg icon-class="iconweixin1" />
          <span>微信绑定</span>
        </div>
        <div class="Userhl_info_rzboxli_r">
          <el-button :disabled="weixintype" size="small" @click="rz(5)">{{
            weixintype ? "已绑定" : "去绑定"
          }}</el-button>
        </div>
      </li>
    </ul>
    <!-- 手机 -->
    <div class="iphonerzbox" v-if="iphone">
      <p>手机认证</p>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入手机号码</div>
        <el-input v-model="phonenum" placeholder="请输入手机号码">
          <el-button
            slot="append"
            :disabled="disabledtype"
            @click="phonemabtn"
            >{{ yzm_tetx }}</el-button
          >
        </el-input>
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入验证码</div>
        <el-input v-model="phonema" placeholder="请输入验证码"></el-input>
      </div>
      <div class="rz_subbtn">
        <el-button @click="back">返回</el-button>
        <el-button @click="phonesub">保存</el-button>
      </div>
    </div>
    <!-- 手机 -->
    <!-- 实名 -->
    <div class="iphonerzbox" v-if="shiming">
      <p>实名认证</p>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入真实姓名</div>
        <el-input v-model="rname" placeholder="请输入真实姓名"></el-input>
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入身份证号</div>
        <el-input v-model="rsfnum" placeholder="请输入身份证号"></el-input>
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入支付宝账号</div>
        <el-input v-model="r_zfbnum" placeholder="请输入支付宝账号"></el-input>
      </div>
      <div class="iphonerzboxinput" v-if="userinfo.groupid == 1">
        <div class="iphonerzboxinputldiv">请上传身份证正、反照片</div>
        <Uploadimg @uploadimgheji="uploadimgurl" :imgall="imgalls" />
      </div>
      <div class="iphonerzboxinput" v-if="userinfo.groupid == 2">
        <div class="iphonerzboxinputldiv">请上传营业执照</div>
        <Uploadimg @uploadimg="uploadimgurl_yy" :imgall="imgallsfalse" />
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv"></div>
        <ul class="sfztujizhanji">
          <li
            v-for="(item, index) in imghejidata"
            :key="index"
            v-show="item != 'undefined' && item != ''"
          >
            <img :src="item" alt="" />
            <img :src="item.src" alt="" />
          </li>
        </ul>
      </div>
      <div class="rz_subbtn">
        <el-button @click="back">返回</el-button>
        <el-button @click="shenfensub">保存</el-button>
      </div>
    </div>
    <!-- 实名 -->
    <!-- 邮箱 -->
    <div class="iphonerzbox" v-if="email">
      <p>邮箱认证</p>
      <div class="iphonerzboxinput" v-if="xinxiall.email != ''">
        <div class="iphonerzboxinputldiv" style="color: #999">
          已认证邮箱地址
        </div>
        <el-input disabled v-model="xinxiall.email" placeholder=""> </el-input>
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入邮箱地址</div>
        <el-input v-model="emailnum" placeholder="">
          <el-button slot="append" :disabled="disabledtype" @click="emailbtn">{{
            yzm_tetx
          }}</el-button>
        </el-input>
      </div>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">请输入邮箱验证码</div>
        <el-input
          v-model="captchanum"
          placeholder="请输入邮箱验证码"
        ></el-input>
      </div>
      <div class="rz_subbtn">
        <el-button @click="back">返回</el-button>
        <el-button @click="emailsubbtn">保存</el-button>
      </div>
    </div>
    <!-- 邮箱 -->
    <!-- 保证金 -->
    <div class="iphonerzbox" v-if="money">
      <p>保证金认证</p>
      <div class="iphonerzboxinput">
        <div class="iphonerzboxinputldiv">缴纳金额</div>
        <el-input v-model="bzjmoney" placeholder="请输入缴纳金额"></el-input>
      </div>
      <div class="rz_subbtn">
        <el-button @click="back">返回</el-button>
        <el-button @click="bzj_jiaona">缴纳</el-button>
      </div>

      <div class="jiaonasmbox">
        <div>缴纳说明</div>
        <div>
          保证金是承接任务的必要条件，用户账号注册完毕进行实名认证，缴纳保障金即可承接任务；缴纳后在未承接任务的状态下可即时提取，保障金提取后收回缴纳保障金的所有权限，保障金不可补缴，可提取后重新缴纳。
        </div>
      </div>
    </div>
    <!-- 保证金 -->
    <!-- 微信 -->
    <div class="iphonerzbox" v-if="weixinbj">
      <p>微信绑定</p>
      <div class="bindwechattitle">使用微信APP扫描下方二维码即可完成绑定</div>
      <div class="bindwechat">
        <img :src="wechatimg" alt="" />
      </div>
      <div class="rz_subbtn">
        <el-button @click="back">返回</el-button>
      </div>
    </div>
    <!-- 微信 -->
    <zhifu :all="dataall" />
  </div>
</template>
<script>
import zhifu from "../../zhifu";
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: { zhifu, Uploadimg },
  props: ["message1"],
  data() {
    return {
      bindwechattypebun: 0,
      wechatimg: "",
      weixintype: false,
      disabledphone: true,
      weixinbj: false,
      phonetext: "",
      imgalls: {
        name: "complex",
        width: 400,
        height: 300,
      },
      imgallsfalse: {
        name: "",
        width: 300,
        height: 400,
      },
      userinfo: {},
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      allbox: true,
      iphone: false,
      shiming: false,
      email: false,
      money: false,

      phonenum: "", //手机号
      phonema: "", //手机验证验证码
      yzm_tetx: "发送验证码",
      timer: null,
      count: 0,
      disabledtype: false,
      rname: "", //真实姓名
      rsfnum: "", //身份证
      r_zfbnum: "", //支付宝账号
      blis: "", //营业执照
      emailnum: "", //邮箱
      captchanum: "", //邮箱验证码
      bzjmoney: "",

      dataall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "10",
      },
      shimingtext: "去认证", //实名
      imghejidata: [], //身份证图集
      xinxiall: {},
    };
  },
  methods: {
    rz(id) {
      this.allbox = false;
      if (id == 1) {
        this.iphone = true;
      } else if (id == 2) {
        this.shiming = true;
      } else if (id == 3) {
        this.email = true;
      } else if (id == 4) {
        this.money = true;
      } else if (id == 5) {
        this.weixinbj = true;
        this.bindwechat();
      }
    },
    bzj_jiaona() {
      var that = this;
      if (that.bzjmoney > 1) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/payMargin",
          method: "post",
          data: {
            money: that.bzjmoney,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.dataall = {
              type: true,
              money: that.bzjmoney,
              ddnnum: res.data.result,
              class_id: "10",
            };
          } else {
          }
        });
      } else {
        this.$message({
          message: "缴纳金额需 大与等于1元！",
          duration: 1000,
        });
      }
    },
    back() {
      this.allbox = true;
      this.iphone = false;
      this.shiming = false;
      this.email = false;
      this.money = false;
      this.weixinbj = false;
      this.bindwechattypebun = 60;
    },
    phonemabtn() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getMobileCode",
          method: "post",
          data: {
            mobile: that.phonenum,
            get_type: "RealMobile",
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message.success("验证码发送成功，请注意查收");
            const TIME_COUNT = 60;
            if (that.timer == null) {
              that.count = TIME_COUNT;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                  that.yzm_tetx = "" + that.count + "秒后重新获取";
                  that.disabledtype = true;
                } else {
                  clearInterval(that.timer);
                  that.timer = null;
                  that.yzm_tetx = "获取验证码";
                  that.disabledtype = false;
                }
              }, 1000);
            }
          } else {
            this.$message({
                    message: res.data.messages,
                    duration: 1000,
                  });
          }
        });
    },
    emailbtn() {
      var that = this;
      that.disabledtype = true;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getEmailCode",
          method: "post",
          data: {
            email: that.emailnum,
          },
        })
        .then((re) => {
          var res = JSON.parse(re.data);
          if (res.code == 1) {
            that.$message.success("验证码发送成功，请注意查收");
            const TIME_COUNT = 60;
            if (that.timer == null) {
              that.count = TIME_COUNT;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                  that.yzm_tetx = "" + that.count + "秒后重新获取";
                  that.disabledtype = true;
                } else {
                  clearInterval(that.timer);
                  that.timer = null;
                  that.yzm_tetx = "获取验证码";
                  that.disabledtype = false;
                }
              }, 1000);
            }
          } else {
            that.disabledtype = false;
            that.$message(res.messages);
          }
        });
    },
    phonesub() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/mobileApprove",
          method: "post",
          data: {
            phone: that.phonenum,
            captcha: that.phonema,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.back();
            that.$message("认证成功");
          } else {
            that.$message(res.data.messages);
          }
        });
    },
    shenfensub() {
      var that = this;
      var id = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
      var re = new RegExp(id);
      if (re.test(that.rsfnum)) {
        // console.log("身份证可用");
      } else {
        // console.log("身份证审核失败");
        that.$message("请输入正确身份证");
        return false;
      }
      var atlas_img = "";
      console.log(this.imghejidata);
      for (var i = 0; i < this.imghejidata.length; i++) {
        if (this.imghejidata[i].src != undefined) {
          atlas_img = atlas_img + this.imghejidata[i].src + ",";
        } else {
          atlas_img = atlas_img + this.imghejidata[i] + ",";
        }
      }
      // return false;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/identityApprove",
          method: "post",
          data: {
            realname: that.rname,
            xyid: that.rsfnum,
            alipay: that.r_zfbnum,
            id_card: atlas_img,
            blis: that.blis,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.back();
            that.$message("认证成功");
          } else {
            that.$message(res.data.messages);
          }
        });
    },
    uploadimgurl(rul) {
      this.imghejidata.push(rul);
    },
    uploadimgurl_yy(rul) {
      this.blis = rul;
      this.imghejidata.push(rul);
    },
    emailsubbtn() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/emailApprove",
          method: "post",
          data: {
            email: that.emailnum,
            captcha: that.captchanum,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            this.back();
            that.$message("认证成功");
          } else {
            that.$message(res.data.messages);
          }
        });
    },
    userxinxi() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getIdentity",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          that.rzuser = res.data.result;
          if (that.rzuser.realname != "") {
            that.shimingtext = "修改认证";
            that.rname = that.rzuser.realname;
            that.rsfnum = that.rzuser.xyid;
            that.r_zfbnum = that.rzuser.alipay;
            that.imghejidata = res.data.result.id_card.split(",");
            console.log(that.imghejidata);
          }
        } else {
        }
      });
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getUserInfoData",
        method: "post",
        data: {},
      }).then((res) => {
        that.xinxiall = res.data.result;
        if (res.data.result.ismobile == 0) {
          that.disabledphone = false;
          that.phonetext = "去认证";
        } else {
          that.disabledphone = true;
          that.phonetext = "已认证";
        }
      });
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/findWechat",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.result.wechat == "0") {
          that.weixintype = false;
        } else {
          that.weixintype = true;
        }
      });
    },
    bindwechat() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/bindWechat",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          that.wechatimg = res.data.result.url;
          that.bindwechattype();
        } else {
        }
      });
    },
    bindwechattype() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/findWechat",
        method: "post",
        data: {},
      }).then((res) => {
        console.log(res);
        if (res.data.result.wechat != "0") {
          that.back();
        } else {
          setTimeout(function () {
            that.bindwechattypebun = that.bindwechattypebun + 1;
            if (that.bindwechattypebun < 60) {
              that.bindwechattype();
            } else {
              that.bindwechattypebun = 0;
            }
          }, 1000);
        }
      });
    },
  },
  created() {
    var that = this;
    that.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    that.userxinxi();
  },
  watch: {},
};
</script>
