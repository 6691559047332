<template>
  <div class="Userhl_info_ziliao">
    <div class="Userhl_info_ziliao_box" style="justify-content: center">
      <div
        class="Userhl_info_ziliao_box_rbox Userhl_info_ziliao_box_rboximgbigbox"
        @mouseenter="imgtype_enter()"
        @mouseleave="imgtype_out()"
      >
        <img
          v-show="imgtype"
          class="Userhl_info_ziliao_box_rboxuimg"
          :src="img"
          alt=""
        />
        <div v-show="imgtypefalse" class="Userhl_info_ziliao_box_rboxuimg_box">
          更换头像
        </div>
        <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
      </div>
    </div>
    <div v-if="user_1">
      <div class="Userhl_info_ziliao_box" style="margin-bottom: 1rem">
        <div class="Userhl_info_ziliao_box_ltext" style="font-weight: bold">
          基本信息
        </div>
        <div class="Userhl_info_ziliao_box_rbox"></div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">昵称</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="usernc" placeholder="请输入昵称"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">真实姓名</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="uname" placeholder="请输入真实姓名"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">性别</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="sextype" placeholder="请选择性别">
            <el-option
              v-for="(item, index) in sex"
              :key="index"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">学校</div>
        <div class="Userhl_info_ziliao_box_rbox" style="position: relative">
          <el-input
            @focus="schoolfocus"
            v-model="school"
            placeholder="请填写学校"
          ></el-input>
          <ul class="schoollb" v-show="schoollbtype">
            <li
              v-for="(item, index) in schoollbli"
              :key="index"
              @click="schoollbliclick(item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">设计专业</div>
        <div
          class="Userhl_info_ziliao_box_rbox Userhl_info_ziliao_box_rboxzhuanye"
          style="display: flex"
        >
          <span class="userinfomajor" v-show="bigleim_z">{{
            options1val_z
          }}</span>
          <span class="userinfomajor" v-show="smleim_z">{{
            options2val_z
          }}</span>
          <el-button class="" v-show="bigleim_z" @click="bigsmtypebtn"
            >修改</el-button
          >
          <el-select
            v-model="options1val"
            placeholder="请选择设计专业"
            style="padding-right: 2%"
            v-show="bigleim"
          >
            <el-option
              v-for="(item, index) in options1"
              :key="index"
              :label="item.classname"
              :value="item.classid"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="options2val"
            placeholder="请选择设计专业"
            style="padding-left: 2%"
            v-show="smleim"
          >
            <el-option
              v-for="(item, index) in options2"
              :key="index"
              :label="item.title"
              :value="item.title"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">工作状态</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="workstype" placeholder="请选择工作状态">
            <el-option
              v-for="(item, index) in optionstype"
              :key="index"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">技能标签</div>
        <div
          class="Userhl_info_ziliao_box_rbox Userhl_info_ziliao_box_rboxbqbox"
        >
          <ul class="Userhl_info_ziliao_box_rbox_bq">
            <li
              v-for="(item, index) in bqtrue"
              :key="index"
              @click="bqtextclick_remove(item.num)"
            >
              -{{ item.text }}
            </li>
          </ul>
          <div class="Userhl_info_ziliao_box_rbox_bqbtn" @click="bqtextclick">
            {{ bqtext }}
          </div>
          <ul
            class="Userhl_info_ziliao_box_rbox_bq Userhl_info_ziliao_box_rbox_bqall"
            v-if="bqalltf"
            :class="bqallc3"
          >
            <li
              @click="bqall(index, item.ft, item.text, item.num)"
              v-for="(item, index) in user_info_iostanbqall"
              :key="index"
              :class="item.classred"
            >
              + {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">QQ</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="qq" placeholder="请输入个人QQ"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">微信</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="wechat" placeholder="请输入个人微信"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">个人简介</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            type="textarea"
            v-model="userall"
            placeholder="请输入个人简介"
          ></el-input>
        </div>
      </div>
      <!-- //////////////// -->
      <div style="margin: 1rem auto; border-top: 1px solid #eee; width: 90%">
        <div class="Userhl_info_ziliao_box" style="width: 74%">
          <div class="Userhl_info_ziliao_box_ltext" style="font-weight: bold">
            获奖信息
          </div>
          <div class="Userhl_info_ziliao_box_rbox"></div>
        </div>
      </div>
      <div class="Userhl_info_hjbox">
        <div class="Userhl_info_hjboxmm">
          <span>获奖情况</span>
          <el-button
            icon="el-icon-circle-plus-outline"
            @click="dialogVisible = true"
            >添加</el-button
          >
        </div>
        <div class="Userhl_info_hjboxmm">
          <span>作品采用情况</span>
          <el-button
            icon="el-icon-circle-plus-outline"
            @click="dialogVisible_zp = true"
            >添加</el-button
          >
        </div>
        <ul>
          <li
            v-for="(item, index) in jiang"
            :key="index"
            v-show="item.text != ''"
          >
            {{ index + 1 }}：{{ item.text }}
          </li>
        </ul>
        <ul>
          <li
            v-for="(item, index) in zuopin"
            :key="index"
            v-show="item.text != ''"
          >
            {{ index + 1 }}：{{ item.text }}
          </li>
        </ul>
      </div>
      <el-dialog
        title="获奖情况"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-input
          v-for="(item, index) in jiang"
          :key="index"
          v-model="item.text"
          placeholder="请输入获奖情况"
          style="margin-bottom: 2rem"
        ></el-input>
        <el-button icon="el-icon-circle-plus-outline" @click="hjadd(1)"
          >添加</el-button
        >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="作品采用情况"
        :visible.sync="dialogVisible_zp"
        width="30%"
        :before-close="handleClose"
      >
        <el-input
          v-for="(item, index) in zuopin"
          :key="index"
          v-model="item.text"
          placeholder="作品采用情况"
          style="margin-bottom: 2rem"
        ></el-input>
        <el-button icon="el-icon-circle-plus-outline" @click="hjadd(2)"
          >添加</el-button
        >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible_zp = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible_zp = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-if="user_2">
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">企业名称</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="qyname"
            placeholder="请输入真实企业名称"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">社会信用代码</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="qynum"
            placeholder="请输入企业社会信用代码"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">企业网址</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="qyurl" placeholder="请输入企业网址"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">企业介绍</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            type="textarea"
            v-model="qytext"
            placeholder="请输入企业介绍"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="infosubbtn" @click="infosubbtn()">提交</div>
  </div>
</template>
<script>
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: {
    Uploadimg,
  },
  data() {
    return {
      imgalls: {
        name: "false",
        width: 400,
        height: 400,
      },
      bigleim: false,
      smleim: false,
      bigleim_z: false,
      smleim_z: false,
      options1val_z: "",
      options2val_z: "",
      userinfo: {},
      dialogVisible: false,
      dialogVisible_zp: false,
      jiang: [{ text: "" }, { text: "" }, { text: "" }],
      user_1: false,
      user_2: false,
      sextype: "",
      uname: "",
      school: "",
      type: "",
      workstype: "",
      qq: "",
      wechat: "",
      userall: "",
      zuopin: [{ text: "" }, { text: "" }, { text: "" }],
      usernc: "",

      qyname: "",
      qynum: "",
      qyurl: "",
      qytext: "",

      imgtypefalse: false,
      options1val: "",
      options2val: "",
      schoollbli: [],
      schoollbtype: false,
      schoolhidden: 0,
      imgtype: true,
      bqallc3: "",
      bqalltf: false,
      bqtext: "展开标签库",
      bqtrue: [],
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
      img: "https://task.hozoin.cn/Works/20210402/sj-0768621535.png",
      input: "",
      value: "",
      options1: [],
      options2: [],
      optionstype: [
        {
          value: "近期可接",
        },
        {
          value: "暂无时间",
        },
      ],
      sex: [
        {
          value: "男",
        },
        {
          value: "女",
        },
      ],
    };
  },
  methods: {
    bqall(index, ft, text, num) {
      var that = this;
      if (Number(ft) == 0) {
        if (that.bqtrue.length > 4) {
          that.$message({
            message: "最多添加五个标签",
            type: "success",
          });
          return false;
        } else {
          this.user_info_iostanbqall[index].classred = "red";
          that.user_info_iostanbqall[index].ft = "1";
          that.$message({
            message: "添加成功",
            type: "success",
          });
          var biaoqiankuxz_li = {
            text: text,
            num: num,
          };
          that.bqtrue.push(biaoqiankuxz_li);
        }
      } else {
        this.user_info_iostanbqall[index].classred = "";
        that.user_info_iostanbqall[index].ft = "0";
        for (var i = 0; i < this.bqtrue.length; i++) {
          if (this.bqtrue[i].text == text) {
            this.bqtrue.splice(i, 1);
          }
        }
        that.$message({
          message: "取消成功",
          type: "warning",
        });
      }
    },
    bqtextclick() {
      var that = this;
      if (that.bqalltf === false) {
        that.bqtext = "收起标签库";
        that.bqalltf = true;
        that.bqallc3 = "bqallc3true";
      } else {
        that.bqtext = "展开标签库";
        that.bqallc3 = "bqallc3false";
        setTimeout(function () {
          that.bqalltf = false;
        }, 500);
      }
    },
    infosubbtn() {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.tokentimeyz();
      var gender = userinfo.groupid;
      // gender = 1;
      if (gender == 1||gender==3) {
        //验证qq
        var strRegex = /^[1-9][0-9]{4,9}$/;
        var re = new RegExp(strRegex);
        if (re.test(that.qq)) {
          // console.log("qq可用");
        } else {
          // console.log("qq审核失败");
          that.$message("请输入正确QQ");
          return false;
        }
        //验证qq
        //验证微信

        //验证微信
        var workstypenum = "";
        if (that.workstype == "近期可接") {
          workstypenum = "1";
        } else {
          workstypenum = "2";
        }
        var sex = "";
        if (that.sextype == "女") {
          sex = "1";
        } else {
          sex = "2";
        }
        let skill = [];

        for (var isk = 0; isk < that.bqtrue.length; isk++) {
          skill.push(that.bqtrue[isk].num);
        }
        that
          .$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/userMeans",
            method: "post",
            data: {
              userpic: that.img,
              username: that.usernc,
              realname: that.uname,
              sex,
              skill,
              saytext: that.userall,
              oicq: that.qq,
              wechat: that.wechat,
              winning: that.jiang,
              works: that.zuopin,
              school: that.school,
              major: that.options2val,
              job_state: workstypenum,
              subjects: that.options1val,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              that.$message(res.data.messages);
              location.reload();
            } else {
              that.$message(res.data.messages);
            }
          });
      } else if (gender == 2) {
        that
          .$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/firmMeans",
            method: "post",
            data: {
              userpic: that.img,
              company: that.qyname,
              saytext: that.qytext,
              homepage: that.qyurl,
              credit: that.qynum,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              that.$message(res.data.messages);
              location.reload();
            } else {
              that.$message(res.data.messages);
            }
          });
      }
    },
    imgtype_enter() {
      var that = this;
      that.imgtypefalse = true;
    },
    imgtype_out() {
      var that = this;
      that.imgtypefalse = false;
    },
    uploadimgurl(rul) {
      this.img = rul;
    },
    schoollbliclick(school) {
      this.school = school;
      this.schoollbtype = false;
      this.schoolhidden = 1;
    },
    schoolfocus() {
      this.schoolhidden = 0;
    },
    bqtextclick_remove(id) {
      var that = this;
      for (var i = 0; i < that.bqtrue.length; i++) {
        if (that.bqtrue[i].num == id) {
          that.bqtrue.splice(i, 1);
        }
      }
      for (var s = 0; s < that.user_info_iostanbqall.length; s++) {
        if (that.user_info_iostanbqall[s].num == id) {
          that.user_info_iostanbqall[s].classred = "";
          that.user_info_iostanbqall[s].ft = "0";
        }
      }
      that.$message({
        message: "取消成功",
        type: "warning",
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    hjadd(id) {
      var hjaddtext = { text: "" };
      if (id == 1) {
        this.jiang.push(hjaddtext);
      } else {
        this.zuopin.push(hjaddtext);
      }
    },
    bigsmtypebtn() {
      this.bigleim_z = false;
      this.smleim_z = false;
      this.bigleim = true;
      this.smleim = true;
    },
  },
  mounted() {
    // console.log(campus)
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    var groupid = userinfo.groupid;
    // groupid =2;
    if (groupid == 2) {
      that.user_2 = true;
    } else {
      that.user_1 = true;
    }
    that
      .$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getClassTopList",
        method: "post",
        data: {},
      })
      .then((res) => {
        if (res.data.code == 1) {
          that.options1 = res.data.result;
        } else {
          that.$message(res.data.messages);
        }
      });
    var userid = userinfo.userid;
    that
      .$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getUserInfo",
        method: "post",
        data: {
          userid,
          groupid,
        },
      })
      .then((res) => {
        if (res.data.code == 1) {
          var resdata = res.data.result;
          //////////  企业
          if (resdata.userpic == "" || resdata.userpic == null) {
            //没头像处理
          } else {
            that.img = resdata.userpic;
          }

          that.qyname = resdata.company;
          that.qynum = resdata.credit;
          that.qyurl = resdata.homepage;
          that.qytext = resdata.saytext;
          //////////

          ///////////////   设计师
          var sex = "";
          if (resdata.sex == 1) {
            sex = "女";
          } else {
            sex = "男";
          }
          that.sextype = sex;
          that.uname = resdata.realname;
          that.school = resdata.school;
          if (resdata.skill != "") {
            var skillbq = resdata.skill.split(",");
            for (var i = 0; i < skillbq.length; i++) {
              var index=Number(skillbq[i])-1;
              that.bqtrue.push(that.user_info_iostanbqall[index]);
              that.user_info_iostanbqall[index].classred = "red";
              that.user_info_iostanbqall[index].ft = "1";
            }
          };
          console.log("111")

          var workstype = "";
          if (resdata.job_state == 1) {
            workstype = "近期可接";
          } else {
            workstype = "暂无时间";
          }
          that.workstype = workstype; //近期工作状态
          that.qq = resdata.oicq;
          that.wechat = resdata.wechat;
          that.userall = resdata.saytext;
          if (
            resdata.winning != "0" &&
            resdata.winning != "null" &&
            resdata.winning != null
          ) {
            that.jiang = JSON.parse(resdata.winning);
          }
          if (
            resdata.works != "0" &&
            resdata.works != "null" &&
            resdata.works != null
          ) {
            that.zuopin = JSON.parse(resdata.works);
          }
          that.schoollbtype = false;
          that.schoolhidden = "1";
          that.usernc = resdata.username;
          for (var s = 0; s < that.options1.length; s++) {
            if (resdata.subjects == that.options1[s].classid) {
              resdata.subjects = that.options1[s].classname;
            } else {
            }
          }
          that.options1val_z = resdata.subjects; //大类
          that.options2val_z = resdata.major; //小类
          if (that.options1val_z != "") {
            that.bigleim_z = true;
            for (var s = 0; s < that.options1.length; s++) {
              if (resdata.subjects == that.options1[s].classid) {
                resdata.subjects = that.options1[s].classname;
              } else {
              }
              that.options1val_z = resdata.subjects;
            }
          } else {
            that.bigleim = true;
          }
          if (that.options2val_z != "") {
            that.smleim_z = true;
          } else {
            that.smleim = true;
          }
          ///////////////
        } else {
          // that.$message(res.data.messages);
        }
      });
  },
  watch: {
    school: function () {
      var that = this;
      if (that.schoolhidden == 0) {
        if (that.school == ""||that.school == undefined) {
          that.schoollbtype = false;
        } else {
          that.schoollbtype = true;
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getSchoolList",
              method: "post",
              data: {
                name: that.school,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                that.schoollbli = res.data.result;
              } else {
              }
            });
        }
      } else {
      }
    },
    options1val: function () {
      var that = this;
      that.options2val = "";
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getClassList",
          method: "post",
          data: {
            class: that.options1val,
            tag: 1,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.options2 = res.data.result;
            that.options2val = that.options2[0].title;
          } else {
            that.$message(res.data.messages);
          }
        });
    },
  },
};
</script>
